#brandDetail_v2 {
  .brand-half-box {
    border-top: none !important;
    h1, h2, h3, h4{
      @apply mb-4;
    }
    .title{
      font-size:26px;
      @media (max-width: 760px) {
        font-size:22px;
      }
      @media (max-width: 640px) {
        font-size:20px;
      }
      @media (max-width: 320px) {
        font-size:18px;
      }
      &.title1 {
        font-size: 85%;
        line-height: 1;
        font-weight: 300;
      }
      &.title2 {
        font-size: 230%;
        line-height: 0.8;
      }
    }

    .image-box{
      .image-wrapper{
        @apply opacity-0 transition-opacity duration-1000 delay-1000 z-10;
        &.active{
          @apply opacity-100 delay-[0s] ease-out z-20;
        }
      }
    }
 
    .text-box {

      .text-frame{
        @apply w-full;
        @screen mdmx {
          .description >*{
            display: none;
          }
          .title, p:first-of-type, .description p:first-of-type{
            display: block !important;
          }
          margin-bottom: 10px;
        }
        @screen md {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
          margin-bottom: 30px;
        }
        .title {
          @apply font-bold text-[20px] sm:text-[22px] md:text-[24px] lg:text-[26px] mb-3 sm:mb-4 md:mb-5 lg:mb-6 leading-[1.4];
        }
        p{
          @apply text-[13px] sm:text-[14px] mb-4 leading-[2];
        }
      }
      .btn-frame {
        @apply w-full;
        .viewBtn, .hideBtn{
          @apply min-w-[120px] lg:min-w-[130px] py-[1em] px-[1.6em] lg:py-[1.2em] lg:px-[2.0em] m-0 bg-[#222] text-white rounded-full text-center;
        }
        .hideBtn {
          @apply hidden;
        }
      }
      
      &.open {
        @apply mb-0;
        .text-frame {
          @apply overflow-hidden h-auto block;
          @screen mdmx {
            *{
              @apply block;
            }
          }
        }
        .hideBtn{
          @apply hidden md:block;
        }
        .viewBtn{
          @apply hidden;
        }
        
      }

      a {
        @screen smmx {
          padding: 9px 15px;
        }

        @screen xsmx {
          padding: 8px 14px;
          font-size: 11px;
        }
      } 
    }
   
  }
}