.fade-{
  &enter-from, &leave-to{
    @apply opacity-0;
  }
  &enter-to, &leave-from{
    @apply opacity-100;
  }
  &enter-active, &leave-active{
    @apply transition-opacity duration-300;
  }
}

.fade-in-{
  &enter-from, &leave-to{
    @apply opacity-0;
  }
  &enter-to, &leave-from{
    @apply opacity-100;
  }
  &enter-active, &leave-active{
    @apply transition-all duration-1000 delay-300 ease-out #{!important};
  }
}

.fade2-{
  &enter-from, &leave-to{
    @apply opacity-0;
  }
  &enter-to, &leave-from{
    @apply opacity-100;
  }
  &enter-active, &leave-active{
    transition: opacity 1s ease-out;
  }
}

.navi-close1-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(-100%)];
  }
  &enter-to, &leave-from{
    @apply translate-x-0;
  }
  &enter-active, &leave-active{
    @apply transition-transform duration-300 ease-out delay-500;
  }
}

.navi-close2-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(100%)];
  }
  &enter-to, &leave-from{
    @apply translate-x-0;
  }
  &enter-active, &leave-active{
    @apply transition-[transform] duration-300 ease-out delay-500;
  }
}

/* .navi-close1-{
  &enter-from, &leave-to{
    @apply opacity-0;
  }
  &enter-to, &leave-from{
    @apply opacity-100;
  }
  &enter-active, &leave-active{
    @apply transition-opacity duration-500 delay-500 ease-out;
  }
}

.navi-close2-{
  &enter-from, &leave-to{
    @apply opacity-100;
  }
  &enter-to, &leave-from{
    @apply opacity-100;
  }
  &enter-active, &leave-active{
    @apply transition-opacity delay-100;
  }
} */

.longer-fade-{
  &enter-from {
    @apply opacity-0;
  }
  &leave-to {
    display: none;
  }
  &enter-to,
  &leave-from {
    @apply opacity-100 translate-y-0;
  }
  &enter-active,
  &leave-active {
    @apply transition-all;
  }
  &enter-active {
    @apply duration-700 ease-out;
  }
}

.fade-in-{
  &enter-from{
    @apply opacity-0;
  }
  &leave-to{
    display: none;
  }
  &enter-to, &leave-from{
    @apply opacity-100 translate-y-0;
  }
  &enter-active, &leave-active{
    @apply transition-all;
  }
  &enter-active{
    @apply duration-300 ease-out;
  }
}

.slide-{
  &enter-from, &leave-to{
    @apply translate-y-[calc(100%_+_5px)];
  }
  &enter-to, &leave-from{
    @apply translate-y-0;
  }
  &enter-active, &leave-active{
    @apply transition-transform duration-300 ease-out;
  }
}

.slide-right-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(-100%)];
  }
  &enter-to, &leave-from{
    @apply translate-x-0;
  }
  &enter-active, &leave-active{
    @apply transition-transform duration-300 ease-out;
  }
}

.slide-left-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(100%)];
  }
  &enter-to, &leave-from{
    @apply translate-x-0;
  }
  &enter-active, &leave-active{
    @apply transition-transform duration-300 ease-out;
  }
}

.slide-right2-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(-120%)];
  }
  &enter-to, &leave-from{
    @apply translate-x-0;
  }
  &enter-active, &leave-active{
    @apply transition-transform duration-300 ease-in-out;
  }
}

.slide-left2-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(120%)];
  }
  &enter-to, &leave-from{
    @apply translate-x-0;
  }
  &enter-active, &leave-active{
    @apply transition-[transform] duration-300 ease-in-out;
  }
}

.slide-filter-{
  &enter-from, &leave-to{
    @apply mr-[-450px];
  }
  &enter-to, &leave-from{
    @apply mr-0;
  }
  &enter-active, &leave-active{
    @apply transition-all duration-300 ease-in-out;
  }
}

.slide-right-with-fade-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(-50%)] opacity-0;
  }
  &enter-to, &leave-from{
    @apply translate-x-0 opacity-100;
    @apply overflow-y-hidden;
  }
  &enter-to{
    @apply overflow-y-auto;
    @apply overflow-y-hidden;
  }
  &enter-active, &leave-active{
    @apply transition-[transform,opacity] duration-300 ease-out;
  }
}

.slide-left-with-fade-{
  &enter-from, &leave-to{
    @apply translate-x-[calc(50%)] opacity-0;
    @apply overflow-y-hidden;
  }
  &enter-to, &leave-from{
    @apply translate-x-0 opacity-100;
    @apply overflow-y-hidden;
  }
  &enter-active, &leave-active{
    @apply transition-[transform,opacity] duration-300 ease-out;
    @apply overflow-y-hidden; 
  }
}

.list-anim-{
  &enter-from, &leave-to{
    height: 0px;
  }
  &enter-to, &leave-from{
    height: 38px;
  }
  &enter-active, &leave-active{
    @apply transition-all duration-300 ease-out;
  }
}


.jquery-slide-down- {

  &enter-from,
  &leave-to {
    overflow: hidden;
    max-height: 0;
  }

  &enter-to,
  &leave-from {
    max-height: 400px;
    overflow: hidden;
  }

  &enter-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
  }

  &leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: ease-out;
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
}

.fade-category-, .fadeproduct-{
  &enter-from,
  &leave-to {
    @apply opacity-0;
  }
  &enter-to,
  &leave-from {
    @apply opacity-100;
  }
  &enter-active{
    @apply transition-opacity duration-500 delay-100 ease-out;
  }
  &leave-active {
    @apply transition-opacity duration-[0s];
  }
}
