.pagenation{
  @apply scale-[.85] xs:scale-90 sm:scale-100;
  a.active{
    @apply text-afgreen font-bold;
    &::after{
      content:"";
      @apply absolute block bg-afgreen m-auto left-0 right-0 bottom-[5px] h-[2px] w-[15px];
    }
  }  
}

