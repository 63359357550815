#brandDetail_v2 {
  background-color: white;
  padding-top: 0px;

  &.product_detail{
    @apply pt-[30px] sm:pt-[60px] lg:pt-[80px] pb-10 sm:pb-24 lg:pb-28;
    .product-advance-wrap{
      header{
        @apply my-12 sm:my-16 lg:my-20;
        .category-title{
          @apply font-rift text-center font-bold text-[3.5em] xs:text-[4.0em] sm:text-[5.0em] md:text-[6.0em] lg:text-[6.5em] leading-[0.9];
        }
      }
      .subcategories{
        @apply text-center flex flex-wrap content-center justify-center gap-2 sm:gap-3 -mb-4;
        .subcategories-btn{
          @apply px-4 h-[36px] sm:h-[38px] lg:h-[40px] min-w-[60px] text-[11px] sm:text-[12px] lg:text-[13px];
        }
      }
    }
  }

  .brandcolorBtn{
    opacity: 1.0;
    &:hover{
      opacity: 0.6;
    }
  }

  .contentsContainer {
    @apply relative mx-auto px-[calc(5vw_+_10px)] xs:px-[40px] sm:px-[60px] md:px-[60px] lg:px-0 lg:max-w-[940px] xl:max-w-[900px] 2xl:max-w-[1000px];
  }

  .contentsWrapper {
    max-width: none;
    padding: 0;

    .breadcrumbs {
      display: none;
    }

    .addthis {
      display: none;
    }

    .content {
      padding: 0;
      margin-top: 75px;
      @screen md {
        margin-top: 0;
      }
    }
  }

  img {
    vertical-align: bottom;
  }

  iframe {
    vertical-align: bottom;
  }

  .center {
    text-align: center;
  }

  .cta-wrap {
    padding: 20px;
    text-align: center;
  }

  a.btn-shop {
    display: block;
    width: 280px;
    margin: 0 auto;
    padding: 15px 25px;
    font-size: 20px;

  }

  .row {
    margin: 0;
    padding: 0;

    &.center {
      text-align: center;      
      img.autofit {
        width: auto;
        max-width: 100%;
      }
    }

    .table-wrap {
      @apply leading-[2];
      table {
        border-collapse: collapse;
        thead {
          th,
          td {
            padding: 5px;
          }
        }
        tbody {
          th,
          td {
            vertical-align: top;
            padding: 5px;
          }
        }
      }
    }

    div.float-left {
      float: left;
      width: 49%;

    }

    div.float-right {
      float: right;
      width: 49%;

    }
  }

  .brand-freehtml {
    @apply leading-[2] max-w-5xl;
    table{
      @apply leading-[2] text-left;
    }
    h2, h3, h4{
      @apply font-bold mb-4 text-left leading-[1.4];
      &.center {
        @apply text-center;
      }
    }
    h2{
      @apply text-[1.6em] sm:text-[1.8em];
    }
    h3{
      @apply text-[1.4em] sm:text-[1.6em];
    }
    h4{
      @apply text-[1.2em] sm:text-[1.4em];
    }
    img{
      @apply mx-auto mt-4 sm:mt-5 md:mt-6 lg:mt-7 mb-8 sm:mb-10 md:mb-12 lg:mb-14;
    }
    .row-block {
      @apply mb-16 sm:mb-20 md:mb-24 lg:mb-28;
      &:last-child{
        @apply mb-0;
      }
      .imgwrap {
        img {
          @apply max-w-full w-auto;
        }
      }

      ul.style1 {
        li {
          padding: 0 0 10px 0;
          margin-left: 20px;
          list-style: circle;
        }
      }
    }
  }

  .brand-links-sp {
    padding: 0.5em 0 2.0em 0;
    width: auto;
    margin: 0 1.15em;
    > div{
      padding: 0.5em 0;
      a {
        width: 90%;
        margin: 0 auto;
        display: block;
        background-color: #000;
        color: #FFF;
        text-align: center;
        padding: 0.5em 0.75em;
      }
    }
  }

  .brand-video{
    @apply relative aspect-video w-full md:max-w-[640px] lg:max-w-[800px] 2xl:max-w-[800px] 3xl:max-w-[1000px] 4xl:max-w-[1200px] mx-auto;
    iframe{
      @apply w-full h-full overflow-hidden md:rounded-[20px] mx-auto;
    }
  }
}