.isotope-container{
  @apply opacity-0 ease-out duration-1000 delay-100;
}

.img-to-preaload {
  display: none;

  &.loaded {
    display: block;
  }
}
