html.no-touch{
  .hover-item {
    @apply transition-all duration-200 ease-out;
    &:hover{
      @apply scale-[1.03] z-10;
      .title{
        @apply opacity-100 py-[16px] leading-[1.4];
      }
    }
    .title{
      @apply transition-all duration-200 ease-out block text-[85%] text-left font-bold px-[15px] opacity-0 my-0 py-0 leading-[0];
    }
  }
}

.center-container{
  @apply flex justify-center items-center;
}

.center-x-container{
  @apply flex justify-center;
}

.card{
  @apply bg-white min-w-[300px] max-w-[400px] rounded-[20px] overflow-hidden shadow-card relative;
}

.page-title{
  @apply relative font-rift font-bold text-[20vw] xs:text-[80px] sm:text-[90px] md:text-[100px] xl:text-[110px] tracking-[0.015em] text-center py-[0.6em] xl:py-[0.7em] leading-[0.85];
  small{
    @apply font-jpsans block mt-[1em] text-[16px] xs:text-[17px] sm:text-[18px] md:text-[20px] xl:text-[22px] font-bold;
  }
}

.list-title{
  @apply text-[15px] font-bold pb-[15px] mb-[20px] border-b border-b-black border-dotted leading-[1.5];
}
.text-white-shadow{
  @screen lg{
    filter:drop-shadow(0px 0px 30px rgba(255,255,255,1)) drop-shadow(0px 0px 30px rgba(255,255,255,1)) drop-shadow(0px 0px 30px rgba(255,255,255,1));
  }
}
.text-black-shadow{
  @screen lg{
    filter:drop-shadow(0px 0px 30px rgba(0,0,0,1));
  }
}

.traiangle{
  @apply w-0 h-0 border-solid border-transparent border-t-[6px] border-b-[6px];
}
.traiangle-right{
  @extend .traiangle;
  @apply  border-l-[#444] border-r-[0px] border-l-[8px];
}
.traiangle-left{
  @extend .traiangle;
  @apply border-r-[#444]  border-r-[8px] border-l-[0px];
}

.arrow-right{
  @apply translate-y-[-2px] relative inline-block w-[9px] h-[9px] border-t-[3px] border-r-[3px] border-solid border-t-[#444] border-r-[#444] rotate-45;
}
.arrow-left{
  @extend .arrow-right;
  @apply rotate-[225deg];
}

.custom-scrollbar{
  &::-webkit-scrollbar {
    .no-touch &{
      width: 10px;
      height: 10px;
    }
  }
  &::-webkit-scrollbar-track {
    .no-touch &{
      background-color: tranparent;
    }
  }
  &::-webkit-scrollbar-thumb {
    .no-touch &{
      border-radius: 10px;
      border: 2px solid rgba(0,0,0,0);
      background-clip: content-box;
      background-color: #999;
    }
  }
}

.scrollbar-hidden{
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display:none;
  }
}