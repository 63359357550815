.modal-container{
  &.modal{
    @apply fixed z-50 left-0 right-0 top-0 bottom-0 p-[calc(5vw_+_10px)] xs:p-[40px] grid place-items-center;
  }
  
  table.shop-info{
    th, td{
      @apply pb-[8px] leading-[1.6] align-top;
    }
    th{
      @apply whitespace-nowrap font-normal;
    }
    tr:last-child{
      th, td{
        @apply pb-0;
      }
    }
  }
  
  .modal-accordion{
    @apply 
    absolute z-10 bottom-0 left-0 w-full max-h-full pt-[45px] px-[40px] pb-[45px] bg-bggray overflow-y-auto;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);
  }
}
