.books{

  .new {
      position: absolute;
      bottom: 0px;
      right: -2px;
      background-color: rgba(190, 9, 9, 0.85);
      color: #f6f2f2;
      font-size: 9px;
      font-weight: bold;
      padding: 12px 6px 12px;
      text-indent: 2px;
      letter-spacing: 1.5px;
      border-top-left-radius: 6px;

      @screen md {
        font-size: 12px;
        padding: 10px 10px 10px 6px;
        border-top-left-radius: 3px;
      }
    }
}
.book-detail{
  .new {
      position: relative;
      display: inline-block;
      background-color: rgb(190, 9, 9);
      color: #f6f2f2;
      font-size: 9px;
      font-weight: bold;
      padding: 4px 4px 4px;
      text-indent: 2px;
      letter-spacing: 1.5px;
      margin-bottom: 5px;
  
      @screen md {
        font-size: 12px;
        padding: 4px 7px 4px;
      }
    }
}