#brandDetail_v2 {
  -webkit-tap-highlight-color: transparent;
  
  .brand-products {
    @apply relative;

    .name{
      @apply pb-[1.6em];
      .product-name {
        @apply relative font-bold text-[1.8em] sm:text-[2em] md:text-[2.2em] lg:text-[2.4em] mb-[0.5em] leading-[1.25] z-10;
      }
      .product-name-en {
        @apply text-[14px] sm:text-[15px]  lg:text-[16px] m-0 leading-[1.3];
      }
    }
    
    p {
      @apply sm:text-[1.1em] mb-[1em] leading-[1.8];
      &.price {
        @apply font-bold text-[1.3em] sm:text-[1.5em] md:text-[1.7em] mb-[1em];
        .jp {
          @apply font-normal text-[60%];
        }
      }
      &.description {
        margin-bottom: 40px;
        line-height: 1.9;
      }
      &.spec,
      &.size,
      &.material {
        padding: 0px 0 0px 0;
      }
    }
		.new-box {
			@apply  text-[10px] scale-[0.85] origin-top-right font-bold uppercase text-white w-auto py-1 pl-2 pr-1 bg-black mr-2;
		}

    .product-header {
      @apply right-0 left-0 box-border z-10 mx-auto lg:max-w-[940px] xl:max-w-[840px] 2xl:max-w-[1000px] 3xl:max-w-[1200px] 4xl:max-w-[1400px];
      &.nav-fixed {
        @apply fixed right-0 left-0 top-[54px] md:top-[74px] xl:top-0  xl:left-[320px] 2xl:left-[360px] pt-5 px-[calc(5vw_+_10px)] xs:px-[40px] sm:px-[60px] md:px-[60px] lg:px-0;
      }
      .inner {
        @apply w-full z-50 relative;
      }
      .product-title{
        @apply cursor-pointer;

        &.advanced-year-block {
          @apply relative text-[14px] sm:text-[15px] scroll-mt-[80px] p-[1.4em] mx-auto mt-[0.5em] mb-[2.2em] cursor-pointer bg-[#F0F0F0] rounded-[50px] text-center z-[1];
          &.focus {
            @apply bg-transparent font-bold mt-14 sm:mt-16 md:mt-24 mb-6 py-0 text-[20px] sm:text-[23px] md:text-[26px] #{!important};
          }
        }
      }
    }

    .product-category {
      @apply mb-16;
      .product-category-text{
        @apply mb-10 sm:mb-12 md:mb-14 text-[13px] sm:text-[14px] font-normal text-center;
      }
      .category-list, &>.product-list{
        &.v2{
          @apply grid grid-cols-4 xs:grid-cols-4 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 3xl:grid-cols-8 gap-2 w-full left-0 right-0 z-30 py-6 bg-white shadow-[0px_6px_6px_-6px_rgba(0,0,0,0.25)]
        }
        &.v3, &.v4 .grid-list{
          @apply flex justify-center content-start flex-wrap relative shadow-none mb-14 -m-2 sm:-m-4 md:-m-6;
        }
        &.v4{
          @apply mb-14 sm:mb-20 md:mb-24;
          .grid-list{
            @apply mb-0;
          }
        }
        .each-category {
          @apply relative py-2 px-[5%] sm:py-3 sm:px-4 md:py-4 md:px-6 lg:py-4 lg:px-6 text-center cursor-pointer opacity-100 hover:opacity-80;
          .img{
            img {
              @apply w-full max-w-none;
            }
          }
          .category-name {
            @apply text-center font-jpsans text-[11px] sm:text-[12px] leading-[1.4] pt-4 break-words;
          }
        }

        .each-v3, .each-v4{
          @apply p-6 transition-opacity mb-4 sm:mb-6 md:mb-8 ;
          &>.info{
            @apply relative hover:opacity-70 cursor-pointer;
          }
          .img{
            img{
              @apply block mx-auto px-4 mb-4;
              .seven_cycles &,
              .rocky_mountain_bicycles & {
                @apply px-0 #{!important};
              }
            }
          }
          .category-name-v3, .category-name-v4, .product-name-v3, .product-name-v4 {
            @apply block text-center text-[12px] sm:text-[13px] font-bold leading-4 break-words pt-5;
          }
          .price-v3, .price-v4 {
            @apply block text-[12px] sm:text-[13px] text-center leading-5 pt-2;
            .jp{
              @apply text-[75%];
            }
          }
        }

        .each-v3 {
          @apply w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/4 2xl:w-1/4 3xl:w-1/5;
          .seven_cycles & {
            @apply w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/3 3xl:w-1/4;
          }
        }
        
        .each-v4 {
          @apply p-2 sm:p-4 md:p-6 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/3 3xl:w-1/4 mb-0 scroll-mt-[80px] md:scroll-mt-[100px] xl:scroll-mt-[50px] #{!important};
        }
        
        h4.product-category-title{
          @apply text-center font-rift text-[2.6em] sm:text-[3.0em] md:text-[3.2em] lg:text-[3.4em] xl:text-[3.6em] font-bold mt-16 sm:mt-20 md:mt-24 lg:mt-28 mb-8 sm:mb-10 md:mb-12;
          scroll-margin-top: 90px;
        }

        .icon{
          @apply absolute top-0 right-0 max-w-[30px] xs:max-w-[35px] sm:max-w-[40px];
          img{
            @apply w-full;
          }
        }
      }
    }
		

    .item-list {
      @apply relative;
      .each{
        @apply inline-block align-top w-1/2 sm:w-1/3 md:w-1/4 2xl:w-1/4 3xl:w-1/5 scroll-mt-[80px] md:scroll-mt-[100px] xl:scroll-mt-[50px];
        .each-inner{
          @apply relative cursor-pointer m-5 lg:m-10 opacity-100 hover:opacity-80;
          .img {
            img {
              @apply w-full max-w-none;
            }
          }
          .product-name{
            @apply text-[12px] text-center py-4 leading-[1.5];
            .new-box {
              @apply block absolute top-0 right-0 text-[10px] scale-[0.85] origin-top-right font-bold uppercase text-white w-auto py-1 px-2;
            }
          }
        }
      }

      @keyframes fadeInAnimation {
        from {
          opacity: 0;
          display: none;
        }
        to {
          opacity: 1;
          display: block;
        }
      }
    }
		

    .detailpanel {
      @apply block bg-white z-10 w-[calc(90vw_-_20px)] xs:w-[calc(100vw_-_80px)] sm:w-[calc(100vw_-_120px)] md:w-[calc(100vw_-_120px)] lg:w-[940px] xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px] 4xl:w-[1400px] mx-auto;
      
      .horizontalarea{
        @apply relative pt-[5px] pb-[50px] my-[30px] mx-auto text-left border-t-[1px] border-b-[1px] border-solid border-[#ddd];
      }

      .moreinfo-close{
        @apply absolute block cursor-pointer top-[15px] lg:top-[30px] right-[-10px] p-[10px] z-[1000] text-center;
        svg{
          @apply h-[22px] sm:h-[24px] md:h-[26px] w-[22px] sm:w-[24px] md:w-[26px] opacity-100 hover:opacity-80;
        }
      }

      img{
        max-width: initial;
        &.loading{
          max-width: 100%;
        }
      }
      .detailcontent{
        @apply scroll-mt-[55px] md:scroll-mt-[75px] xl:scroll-mt-0;
        .imagelist{
          @apply relative mt-[60px] lg:mt-[100px] lg:pr-[20px];
          .mips-box, .d3o-box {
            @apply absolute block h-auto top-2 right-2 lg:right-7 z-[100];
            img {
              @apply w-full;
            }
          }
          .mips-box{
            @apply w-[40px] sm:w-[45px] md:w-[50px];
          }
          .d3o-box {
            @apply w-[55px] sm:w-[60px] md:w-[65px];
          }
          .zoom-image-wrap{
            @apply relative flex justify-center overflow-hidden;

            
          }
          ul.thum-image-wrap{
            @apply flex flex-wrap gap-[8px] sm:gap-[10px] mx-[-4px] sm:mx-[-5px] sm:my-0  justify-center content-center pt-[20px];
            li {
              @apply border w-[calc(100%_/_8_-_8px)] max-w-[40px] aspect-square bg-white border-solid border-[#ddd] rounded-full overflow-hidden [&.active]:border-[#999] cursor-pointer;
              img{
                @apply w-full max-w-none hover:opacity-80 object-cover;
              }
            }
          }
        }
        .inner {
          @apply lg:pl-[20px];
        }
      }
      
      // detail panel common 
      .info {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px 0 0;
        @screen lg {
          margin: 100px 0;
        }

        /* &>.inner {

          .brandcolorBtn{
            display: block;;
            width: 220px;
            text-align: centeLinkr;;
            padding: 5px 10px;
            background-color: #000;
            color: #FFF;
            font-size: 1.7em;
            margin: 5px 0;
          }

          .linkBtn {
            width: 220px;
            display: block;
            margin: 0 auto;
            text-align: center;
            padding: 10px 15px;
            font-size: 18px;
            font-weight: bold;
            
            @screen lg{
              display: inline-block;
              margin: 0 auto 0 0;
            }
          }

          .youtubelinks {
            margin-top: 25px;
            text-align: left;
            h4 {
              display: block;
              padding: 10px 0;
            }
            a {
              margin-right: 10px;
              display: inline-block;
              padding: 3px 0;
            }
          }
        } */
      }
    }

    &.product-advance-wrap{

      .product-section{

        .each-category {
          scroll-margin-top: 90px;
          /* .btn-more-info-wrap{
            text-align: center;

            button{
              display: inline-block;
              background-color: #000;
              color: #fff;
              padding: 1.0em 3.0em;
              margin: 0.25em 0.5em;
            }
          } */
        }
        
        .product-table,
        .spec-table {
          @apply hidden overflow-hidden leading-[1.4] mt-10 rounded-[20px] border border-solid border-[#eee];
          &.on{
            @apply block;
          }
          table {
            @apply w-full;
            thead, tbody {
              tr:nth-child(even) {
                background: #f9f9f9;
              }
              tr {
                @apply text-[12px] sm:text-[13px];
                th {
                  @apply w-[15%] min-w-[100px] sm:min-w-[200px];
                }
                td, th {
                  @apply text-left p-4 sm:py-5 md:p-6;
                }
              }
            }
          }
        }
        .spec-table {
           @apply block;
        }
      }
    }
    
  }

  .youtube-wrap,
  .vimeo-wrap {
    @apply rounded-[20px] overflow-hidden my-12 sm:my-16 lg:my-20;
  }
}