button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none
}

.btn-circle, .btn-rounded{
  @apply relative flex flex-wrap justify-center content-center bg-bggray text-[13px] rounded-full h-[55px];
  @apply [.no-touch_&]:hover:brightness-[0.95];
  transition: all 0.2s ease-out;
}
.btn-rounded.instagram, .btn-rounded.line, .btn-rounded.aandf{
  @apply text-white font-medium;
  .sns-icon {
    @apply absolute w-[24px] h-[24px] left-[20px] top-0 bottom-0 my-auto;
  }
}
.btn-rounded.instagram{
  @apply bg-gradient-to-r from-[#6f11c7] to-[#e3004c]
}
.btn-rounded.line{
  @apply bg-[#02C755]
}
.btn-rounded.aandf{
  @apply bg-[#004e2f];
  font-size: 0.9em;
  line-height: 1.3;
  color: #FFF;

  &:hover{
     background-color: #186548;
  }
}
.btn-hamburger {
  @apply absolute left-0 h-[55px] w-[55px] z-10 flex justify-center items-center;
  span ,span::before, span::after{
    @apply absolute block h-[2px] w-[20px] bg-black;
  }
  span::before{
    @apply content-[''] bottom-[6px];
  }
  span::after{
    @apply content-[''] top-[6px];
  }
}

.btn-star{
  @apply absolute right-0 h-[55px] w-[55px] z-10 flex justify-center items-center;
}

.modal-accordion .btn-rounded{
  @apply bg-white;
}

.btn-close{
  @apply block absolute p-[25px] top-0;
  &.white{
    span{
      @apply bg-white
    }
  }
  span{
    @apply absolute h-[13px] w-[1.5px] bg-black -translate-x-1/2  -translate-y-1/2;
    &:first-child{
      @apply rotate-45;
    }
    &:last-child{
      @apply -rotate-45;
    }
  }
}

.btn-toggle-star{
  &.active{
    .star{
      @apply fill-active stroke-active #{!important};
    }
  }
}

.btn-toggle-check{
  @apply absolute w-full h-full top-0 left-0 text-left py-[10px];
  span{
    @apply absolute top-[11px] inline-flex flex-wrap justify-center content-center border border-black rounded-[3px] w-[16px] h-[16px];
  }
  .check{
    @apply hidden fill-white;
  }
  &.active{
    span{
      @apply border-active bg-active;
      .check{
        @apply block;
      }
    }
  }
}

.btn-set{
  @apply flex mt-[30px];
  li{
    @apply box-content;
    &:nth-child(1){
      @apply w-[55px];
    }
    &:nth-child(2){
      @apply flex-1 pl-[10px] pr-[10px];
    }
    &:nth-child(3){
      @apply w-[55px];
    }
    a{
      @apply m-0;
    }
  }
}

