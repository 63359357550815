.story-detail{
  h1{
    @apply text-[17px] xs:text-[18px] sm:text-[19px] lg:text-[20px] font-bold mb-[1.5em] leading-[1.6];
  }
  .lead{
    @apply text-[15px] sm:text-[16px] text-afgreen font-bold mb-[1em] leading-[1.7];
  }
  p, blockquote{
    @apply leading-[2.2] mb-[20px] sm:mb-[30px];
  }
  p:last-of-type{
    @apply text-[12px] leading-[1.6];
  }
  .img-box{
    .img-wrap{
      img{
        @apply mx-auto mb-[10px];
      }
    }
    p{
      @apply text-[11px] text-afgreen leading-[1.6];
    }
  }
}