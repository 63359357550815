#app{
  @apply flex flex-col xl:flex-row w-full overflow-x-hidden;
  
  .header-wrapper, .navi{
    @apply xl:w-navi-xl 2xl:w-navi-2xl;
  }
  
  .main-body{
    @apply xl:w-main-xl 2xl:w-main-2xl mt-[55px] md:mt-[75px] xl:mt-auto relative z-[1] grow;
  }
  
  .main-contents{
    @apply pb-[100px];
  }
  
  .detail-container{
    @apply pb-[50px] sm:pb-[100px] sm:pt-[50px] max-w-[640px] sm:max-w-[550px] md:max-w-[750px] lg:max-w-none mx-auto md:px-[30px];
    .detail-contents{
      @apply md:flex md:justify-center md:items-start;
    }
    .main{
      @apply overflow-hidden max-w-[640px] sm:max-w-[600px] md:w-[500px] lg:w-auto sm:rounded-[30px] sm:shadow-card sm:mb-[40px] md:mb-0;
      .main-contents{
        @apply sm:text-[14px] leading-[1.8] py-[30px] sm:py-[40px] px-[8vw] sm:px-[50px];
        .list-contents{
          @apply min-h-[50px] max-h-[300px] overflow-y-auto pr-[30px] mr-[-30px] mb-[20px];
        }
      }
      img{
        @apply block mx-auto;
      }
    }
  }
  .side{
    
    @apply px-[8vw] sm:px-0 md:p-[20px] md:mt-[-20px] md:mb-0 md:ml-0 md:mr-[-20px] lg:p-[30px] lg:mt-[-30px] lg:mr-[-30px] box-content md:w-[200px] lg:w-[220px];
    .list-title{
      @apply md:hidden;
    }
    .side-contents{
      ul{
        @apply m-[-10px];
        li{
          @apply p-[10px] w-1/2 sm:w-1/2 md:w-full;
          a{
            @apply relative block w-full md:w-full bg-white rounded-[20px] overflow-hidden shadow-card;
          }
        }
      }
    }
    &.brand{
      @apply p-0 m-0 w-full;
      .list-title{
        @apply md:block lg:hidden mt-[50px];
      }
      .side-contents{
        ul{
          li{
            @apply p-[10px] w-1/2 sm:w-1/2 md:w-1/3;
            a{
              @apply md:w-full;
            }
          }
        }
      }
    }
    &.brand{
      &.v2{
        .side-contents{
          ul{
            li{
              @apply p-[10px] w-1/2 sm:w-1/3 md:w-1/3 lg:w-1/4 3xl:w-1/5;
            }
          }
        }
      }
      
    }
  }
}
