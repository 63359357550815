.faq , .faq-detail {
  .back{
    @apply mt-[40px] xs:scale-[1.1] sm:scale-[1.2];
  }
}

.faq {
  ul{
    li.outer {
      @apply relative block bg-white rounded-[15px] sm:rounded-[15px];
      .inner {
        @apply flex items-center py-[25px] pr-[30px] mb-[15px] sm:mb-[25px];
        .img {
          @apply w-[80px] h-[80px] min-w-[80px] min-h-[80px] mx-[10px] p-[10px] flex items-center justify-center absolute;
          img{
            @apply max-h-full
          }
        }
        .info {
          @apply pl-[100px] pr-[20px];
          @media screen and (max-width:350px) {
            @apply pr-0;
          }
          h3 {
            @apply mt-[5px] mb-[10px] font-bold text-[110%];
          }
          p {
            @apply leading-[1.8] text-[105%];
          }
        }
      }
      .af-icon{
        @apply absolute right-[20px];
        @media screen and (max-width:350px) {
          @apply hidden;
        }
        path{
          @apply fill-afgreen;
        }
      }
    }
  }
}

.faq-detail {
  .outer {
    @apply block bg-white rounded-[20px] sm:rounded-[20px];
    .inner {
      @apply py-[60px] px-[30px] xs:px-[35px] sm:p-[50px];
      .label {
        @apply hidden ml-[-10px] font-rift font-bold text-afgreen text-[36px] xs:text-[36px] sm:text-[40px] pr-[15px] xs:pr-[20px] sm:pr-[25px] leading-[1];
      }
      .question{
        @apply flex items-center mb-[12px] sm:mb-[18px];
        h3{
          @apply font-bold text-afgreen text-[115%] sm:text-[125%] leading-[1.6];
        }
      }
      .answer{
        @apply flex items-start;
        .description{
          p{
            @apply leading-[2.2] mt-0 mb-[20px] #{!important};
            a{
              @apply text-afgreen inline-block;
            }
            &:has(a){
              @apply my-[10px];
            }
            &:last-child{
              @apply mb-0 #{!important};
            }
          }
          
          img{
            @apply mt-0 mb-[20px];
            &:last-child{
              @apply mb-0 #{!important};
            }
          }
        }
      }
    }
  }
}

.filter-open{
	.header{
		display: none	;
	}
}