.form-elemeht-style{
  @apply relative block py-[15px] px-[20px] rounded-[30px] leading-normal w-full border-[1px];
}

.af-icon-style{
  @apply absolute top-1/2 -translate-y-1/2 right-[20px];
}

form, .contact-modal{
  button[type="submit"], button[type="button"], {
    @extend .form-elemeht-style;
    @apply font-medium text-[15px] text-center text-white bg-[#707070] drop-shadow-[0_1px_0px_rgba(0,0,0,1)];
    .af-icon{
      @extend .af-icon-style;
      @apply scale-75;
      .arrow{
        @apply fill-white;
      }
    }
  }
}

html.touch{
  .form-input::placeholder {
    @apply text-[13px];
    @apply relative top-[-2px]
  }
}

form{
  
  .form-label {
    @apply mb-[12px] block text-[#333] select-none;
  }
  
  .form-input, .form-textarea{
    @apply focus:text-[16px] sm:focus:text-[13px] sm:focus:h-auto;
  }

  .form-input{
    @apply relative focus:h-[51.5px];
  }
  
  .form-textarea{
    @apply rounded-[20px];
  }

  .form-input, .form-textarea, .form-select {
    @extend .form-elemeht-style;
    @apply text-left text-[#777] border-[#ccc] bg-white appearance-none focus:border-indigo-400 focus:ring;
    &::placeholder {
      @apply text-[#777] opacity-100;
    }
  }

  .form-input+.af-icon{
    @extend .af-icon-style;
    .search{
      @apply fill-[#999];
    }
  }
    

  .form-select {
    @apply pr-6;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAAQtJREFUOBG1lEEOgjAQRalbGj2OG9caOACn4ALGtfEuHACiazceR1PWOH/CNA3aMiTaBDpt/7zPdBKy7M/DCL9pGkvxxVp7KsvyJftL5rZt1865M+Ucq6pyyF3hNcI7Cuu+728QYn/JQA5yKaempxuZmQngOwEaYx55nu+1lQh8GIatMGi+01NwBcEmhxBqK4nAPZJ78K0KKFAJmR3oPp8+Iwgob0Oa6+TLoeCvRx+mTUYf/FVBGTPRwDkfLxnaSrRwcH0FWhNOmrkWYbE2XEicqgSa1J0LQ+aPCuQgZiLnwewbGuz5MGoAhcIkCQcjaTBjMgtXGURMVHC1wcQEy0J+Zlj8bKAnY1/UzDe2dbAVqfXn6wAAAABJRU5ErkJggg==');
    background-size: 0.7rem;
    background-repeat: no-repeat;
    background-position: right 20px center;
    &::-ms-expand {
      @apply opacity-0;
    }
  }

  .form-input.error,
  .form-textarea.error,
  .form-select.error {
    @apply border-red-500 focus:ring focus:ring-red-200;
  }

  .form-error {
    @apply text-red-700 mt-2 text-sm;
  }


  .btn-indigo {
    @apply px-6 py-3 rounded bg-indigo-600 text-white text-sm leading-4 font-bold whitespace-nowrap hover:bg-orange-400 focus:bg-orange-400;
  }
  .btn-gray {
    @apply px-6 py-3 rounded bg-gray-800 text-white text-sm leading-4 font-bold whitespace-nowrap hover:bg-gray-400 focus:bg-gray-400;
  }

  .btn-spinner,
  .btn-spinner:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
  }

  .btn-spinner {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.2em solid white;
    border-right: 0.2em solid white;
    border-bottom: 0.2em solid white;
    border-left: 0.2em solid transparent;
    transform: translateZ(0);
    animation: spinning 1s infinite linear;
  }

  @keyframes spinning {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
