.af-icon{
  @apply w-[15px] h-auto text-transparent;
  .outline{
    @apply fill-none stroke-black;
    stroke-width:0.8px;
  }
  .star-active{
    @apply fill-active stroke-active;
    stroke-width:0.8px;
  }
}

.btn-circle{
  .af-icon{
    @apply w-[15px] xs:w-[15px] sm:w-[15px] md:w-[15px];
  }
}

.btn-rounded{
  .af-icon{
    @apply w-[10px] xs:w-[10px] sm:w-[10px] md:w-[10px] 
    absolute top-1/2 -translate-y-1/2 right-[20px];
  }
  .fill-none {
    fill: none;
  }
}

.btn-set{
  .af-icon{
    @apply right-[12%] xs:right-[20px];
  }
}

.btn-toggle-star.no-outline:not(.active){
  .af-icon{
    .star.outline{
      @apply fill-[#ccc] stroke-[#ccc];
    }
  }
}


.svg-fill-white {
  fill: #FFFFFF;
}

.footer{
  .sns-icon{
    @apply xl:fill-[#333];
  }
}