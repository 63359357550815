#brandDetail_v2 {
  
  .brand-visual {
      position: relative;
  
      .visual {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #fafafa;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
  
        @screen xs {
          height: 400px;
        }
        @screen sm {
          height: 500px;
        }
        @screen md {
          height: 600px;
        }
  
        @screen lg {
          height: 680px;
        }
  
        @screen xl {
          height: 720px;
        }
  
        .cover {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }
  
      .photo {
        display: none;
      }
  
      .logo {
        position: absolute;
        width: 400px;
        max-width: 50%;
      }
      .title {
        position: absolute;
        font-weight: 700;
        font-size: 58px;
        letter-spacing: -0.4px;
        line-height: 0.82;
        color: white;
        top: 285px;
        left: 13%;

        @screen lgmx {
          top: 200px;
          left: 8%;
          font-size: 50px;
        }
      }
      p {
        position: absolute;
        width: 450px;
        font-weight: bold;
        font-size: 15px;
        line-height: 1.6;
        color: white;
      
        @screen lgmx{
          width: 340px;
          font-size: 14px;
        }
      
        @screen mdmx {
          position: relative;
          top: 0;
          left: 0;
          transform: none !important;
          text-shadow: none !important;
          width: auto;
          padding: 20px;
          color: #222;
          font-size: 13px;
        }
      }
    }
}