/* Reset */
@tailwind base;
@import 'reset';

// /* Components */
@tailwind components;

@import 'buttons';
@import 'form';

// /* Utilities */
@tailwind utilities;

html{
  &.navi-open, &.favorite-open, &.filter-open, &.modal-open{
    @apply overflow-hidden;
    &:not(.home){
      body{
        @apply overflow-y-scroll;
      }
    }
  }
  body{
    @apply font-jpsans text-[13px] bg-white min-h-screen;
  }
}

.clearfix:before,
.clearfix:after {
 content: " ";
 display: table;
}
.clearfix:after {
 clear: both;
}
.clearfix {
 *zoom: 1;
}
.svg-fill-white{
  fill: #FFFFFF;
}
.img-to-preaload{
  display: none;
  &.loaded{
    display: block;
  }
}
   
// .VueHoverfade-enter-active,.VueHoverfade-leave-active{transition:opacity .5s}.VueHoverfade-enter,.VueHoverfade-leave-to{opacity:0}.vh--outer[v-cloak]{display:none}.vh--flex{display:flex}.vh--jc{justify-content:center}.vh--ai{align-items:center}.vh--rel{position:relative}.vh--abs{position:absolute}.vh--outer{display:inline-block;line-height:0;font-family:Arial,Helvetica,sans-serif;color:#fff}.vh--holder{overflow:hidden;touch-action:manipulation;cursor:zoom-in;align-items:flex-start}.vh--image{top:0;left:0;pointer-events:none}.vh--message{background-color:#000000a6;padding:8px 15px;border-radius:50px;text-align:center;line-height:initial}.vh--message-top{top:20px}.vh--message-bottom{bottom:20px}.vh--icon{transform:rotate(-45deg);display:block;font-size:20px;margin-right:5px;line-height:20px}.vh--close{line-height:0;background-color:#000000a6;border-radius:50px;font-size:23px;cursor:pointer;height:28px;width:28px}.vh--top-left{top:5px;left:5px}.vh--top-right{top:5px;right:5px}.vh--top-center{top:5px;left:50%;transform:translate(-50%)}.vh--bottom-left{bottom:5px;left:5px}.vh--bottom-right{bottom:5px;right:5px}.vh--bottom-center{bottom:5px;left:50%;transform:translate(-50%)}.vh--loading-o{top:0;left:0;width:100%;height:100%;background-color:#000000a6;pointer-events:none}.vh--loading{top:50%;left:50%;font-size:60px;line-height:60px;animation:vuehoverzoomspin 1s linear infinite;width:36px;height:70px}.vh--none{opacity:0}.vh--no-click img{pointer-events:none}@keyframes vuehoverzoomspin{0%{transform:rotate(0)}to{transform:rotate(360deg)}}

@import 'layout';
@import 'transitions';
@import 'module';
@import 'carousel';
@import 'home';
@import 'store';
@import 'book';
@import 'faq';
@import 'contact';
@import 'about';
@import 'news';
@import 'story';
@import 'zoomviewer';
@import 'vueform';
@import 'isotope';
@import 'brand';
@import 'branddetailv2';
@import 'brands/branddetailv2keyVisual';
@import 'brands/branddetailv2halfBox';
@import 'brands/branddetailv2Carousel';
@import 'brands/branddetailv2Product';
@import 'icons';
@import 'modal';
@import 'pagenation';

.ext-link {
	@apply  block py-8 text-center font-bold text-lg
}

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0
}

[data-tippy-root] {
  max-width: calc(100vw - 10px)
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity
}

.tippy-box[data-placement^=top]>.tippy-arrow {
  bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
  top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom
}

.tippy-box[data-placement^=left]>.tippy-arrow {
  right: 0
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left
}

.tippy-box[data-placement^=right]>.tippy-arrow {
  left: 0
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid
}

.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1
}