.brand-detail{
  ul#thumbnails{
    @apply flex flex-wrap gap-[8px] sm:gap-[10px] mx-[-4px] sm:mx-[-5px] sm:my-0  justify-center content-center;
    li {
      @apply border w-[calc(100%_/_8_-_8px)] max-w-[40px] bg-white border-solid border-[#ddd] rounded-full overflow-hidden [&.active]:border-[#999] cursor-pointer;
    }
  }
  .title{
    @apply text-[16px] md:text-[18px] font-bold mb-[10px] md:mb-[20px];
  }
  .description{
    @apply leading-[2] mb-[30px];
  }
  .btn-list{
    @apply grid sm:grid-cols-2
  }
}
