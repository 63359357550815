.about{
  background: url(https://img.aandf.co.jp/aandfcojp/img/corporate/about_bg.jpg) repeat top left;
  background-size: cover;
  


  &.history{
    .page-title{
      @apply text-afgreen pb-[10px] sm:pb-[30px] lg:pb-[50px] #{!important};
      small{
        @apply font-jpsans #{!important};
      }
    }
  }
  .section{
    @apply items-center grid grid-cols-1 md:grid-cols-2 mb-[40px] sm:mb-[80px];
    .left{
       @apply md:order-1 md:mr-[50px]
    }
    .right{
      @apply md:order-2
    }
    img{
      @apply mb-[20px] md:mb-0;
    }
    h3 {
      @apply font-bold text-afgreen text-[130%] sm:text-[140%] lg:text-[150%] leading-[1.6] mb-[20px];
      br{
        @apply hidden sm:block;
      }
    }
    p {
      @apply text-sm leading-[2] mb-[20px];

    }
  }

  table.simple{
    @apply text-[14px] sm:text-[15px] border-b border-b-[#999] border-dotted w-full;
    tr{
      @apply border-t border-t-[#999] border-dotted;
      th, td{
        @apply leading-[1.7] align-top text-left py-[30px];
        a{
          @apply mt-[10px] text-afgreen font-medium text-[14px] inline-block right-[8px];
          .arrow-right{
            @apply scale-[0.8] translate-y-[0px];
          }
        }
        ul{
          li{
            @apply mb-[10px] [&:last-child]:mb-0;
          }
        }
      }
      th{
        @apply text-afgreen whitespace-nowrap pr-[20px] sm:pr-[25px] lg:pr-[30px];
      }
      tr:last-child{
        th, td{
          @apply pb-0;
        }
      }
    }
  }

  &.privacy{
    h3,h4{
      @apply text-[16px] sm:text-[17px] lg:text-[18px] text-afgreen font-bold mt-[40px];
    }
    h4 {
      @apply mt-0;
    }
    p{
      @apply leading-[2] mt-[15px] sm:mt-[15px];
    }
    ul.number{
      @apply list-decimal mt-[10px] pl-[1.6em];
      li{
        @apply leading-[1.4] mb-[8px];
      }
    }
  }

  &.knife{
    h2, h3, h4 {
      @apply font-bold leading-[1.5];
    }
    h3{
      @apply text-afgreen text-[16px] sm:text-[17px] mt-[40px] sm:mt-[50px] indent-[-0.5em];
    }
    h4 {
      @apply text-[14px] sm:text-[15px] mt-[30px] sm:mt-[40px] mb-[-5px] sm:mb-[-10px];
    }
    p{
      @apply leading-[2.2] mt-[10px] sm:mt-[15px] sm:text-[115%];
    }
    dl.notice{
      @apply block text-[95%] leading-[1.8] pt-[15px];
      div{
      @apply relative flex;
        dt {
          @apply whitespace-nowrap;
        }
        dd {
          @apply grow mb-[10px];
        }
      }
    }
  }
}

.about-2024{
	&.history{
		.header-img-block{
			background-image: url(https://img.aandf.co.jp/aandfcojp/img/corporate/aboutImg_00.jpg);
			background-size: cover;
			background-position: center;
			height: 80vh;
			margin-bottom: 10px;
		}
    .page-title{
      @apply text-afgreen pb-[10px] sm:pb-[30px] lg:pb-[50px] #{!important};

      small{
        @apply font-jpsans #{!important};
      }
    }

		h3{
			@apply text-xl leading-[2] mb-[20px];
			font-weight: bold;
			small{
				display: block;
			}
		}

		p {
      @apply text-sm leading-[2] mb-[20px];

			&.block-end{
				@apply mb-[50px];
			}

			&.caption{
				padding: 5px 0;
				// font-style: italic;
				font-size: 85%;
				font-weight: bold;;
			}
    }
		.imgfloat{
			float:right;
			width: 40%;
			margin-left: 15px;
			margin-bottom: 15px;

			&.smaller{
				width: 25%;
			}
		}
		.imgflex{
			display: flex;
			img{
				width: 25%;
				margin-left: 35px;
				height: 100%;
			}
			p{
				width: calc(75% - 35px);
			}
		}
  }
}