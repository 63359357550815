html.home{
  &::before{
    @apply bg-[url('/img/home/DJI_0006.webp')] content-[''] block absolute z-[1] top-0 right-0 w-full h-[100dvh] xl:w-main-xl 2xl:w-main-2xl bg-no-repeat bg-cover bg-center;
  }
  #app{
    @apply w-screen h-screen flex flex-col xl:flex-row overflow-x-hidden;    
    .header{
      @apply relative;
    }
    .main-body{
      @apply snap-y snap-mandatory grow overflow-y-auto mt-auto h-full;
      // scroll-behavior: smooth;
    }
    .footer{
      @apply snap-start;
    }
  }
  .news-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}