.store-list{
  .area-container{
    @apply mb-[40px];
    .area-title{
      @apply mb-4 border-t border-dotted border-t-black;
      span{
        @apply font-medium text-[12px] xs:text-[13px] sm:text-[14px] inline-block bg-black text-white pt-[10px] pb-[11px] px-[12px] leading-none mt-[-1px];
      }
    }
    .shop-container{
      @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 mx-[-2vw] xs:mx-[-10px];
      .store-card{
        @apply p-[2vw] xs:p-[10px];
        .store-card-thumb{
          @apply rounded-[20px] overflow-hidden mb-[12px];
        }
        .store-card-name{
          @apply min-h-[50px] w-[calc(100%_-_20px)] text-[12px] xs:text-[13px] text-left leading-[1.4] font-semibold;
        }
        .store-card-star{
          @apply absolute top-[4px] right-0 [&_svg]:w-[12px] [&_svg]:h-[12px]
        }
      }
    }  
  }
}
.finder-each-name{
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 40px;
    height: 15px;
    right: -45px;
    top: 5px;
    ;
  }
 
  &.rocky_dealer_1:after {
    background: #FFF url(/img/finder/rocky_official_dealer_starts_1.png) no-repeat left top;
  }

  &.rocky_dealer_2:after {
    background: #FFF url(/img/finder/rocky_official_dealer_starts_2.png) no-repeat left top;
  }

  &.rocky_dealer_3:after {
    background: #FFF url(/img/finder/rocky_official_dealer_starts_3.png) no-repeat left top;
  }



  &.kona_dealer_1:after {
    background: #FFF url(/img/finder/kona_official_dealer_starts_1.png) no-repeat left top;
  }

  &.kona_dealer_2:after {
    background: #FFF url(/img/finder/kona_official_dealer_starts_2.png) no-repeat left top;
  }

  &.kona_dealer_3:after {
    background: #FFF url(/img/finder/kona_official_dealer_starts_3.png) no-repeat left top;
  }
}

.mr-icon-wrap{
  .title-label{
    @apply text-[12px] xs:text-[12px] sm:text-[12px];
    display: inline-block;
    margin-right: 10px;
  }

  .img-group {
    display: inline-block;
    @apply py-1;

    img {
      width: 25px;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.categoryBlock {
  display: block;
  margin: 25px auto 10px auto;
  background: #FFF;
  line-height: 1.2;
  padding-bottom: 30px;

  @apply border-b border-b-black border-dotted;

  h4 {
    margin-bottom: 15px;
    font-weight: bold;
    ;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      width: 20%;

      img {
        width: 36px;
        display: block;
        margin: 0 auto 15px auto;
      }

      span {
        position: relative;
        display: block;
        text-align: left;
        top: 0px;
        // margin-left: 10px;

      }
    }

    &.mysteryranch-dealers {
      span {
        text-align: center;
        ;
      }

      

    }

    &.rocky-dealers {
      padding: 0px 0 0 0;

      li {
        float: none;
        width: 100%;
        position: relative;

        @screen xs {
          padding: 5px 0;
        }

        @screen sm {}

        @screen md {}

        padding:10px 0;

        @screen lg {}

        &:last-child {
          padding-bottom: 0;
        }


        span.txt {
          display: block;
          position: relative;
          margin-left: 45px;
          font-size: 12px;
          top: 0;
          line-height: 1.7;


          @screen xs {}

          @screen sm {}

          @screen md {
            font-size: 14px;
          }

          @screen lg {}

          &.txt1:before {
            display: block;
            position: absolute;
            content: '  ';
            background: #FFF url(/img/finder/rocky_official_dealer_starts_3.png) no-repeat right top;
            width: 40px;
            height: 15px;
            left: -45px;
            top: 5px;

          }

          &.txt2:before {
            display: block;
            position: absolute;
            content: ' ';
            background: #FFF url(/img/finder/rocky_official_dealer_starts_2.png) no-repeat right top;
            width: 40px;
            height: 15px;
            left: -45px;
            top: 5px;

          }

          &.txt3:before {
            display: block;
            position: absolute;
            content: ' ';
            background: #FFF url(/img/finder/rocky_official_dealer_starts_1.png) no-repeat right top;
            width: 40px;
            height: 15px;
            left: -45px;
            top: 5px;

          }
        }
      }
    }

    // rocky-dealers


    &.kona-dealers {
      padding: 0px 0 0 0;

      li {
        float: none;
        width: 100%;
        position: relative;

        @screen xs {
          padding: 5px 0;
        }

        @screen sm {}

        @screen md {}

        padding:10px 0;

        @screen lg {}

        &:last-child {
          padding-bottom: 0;
        }

        span.txt {
          display: block;
          position: relative;
          margin-left: 45px;
          font-size: 12px;
          top: 0;
          line-height: 1.7;

          @screen xs {}

          @screen sm {}

          @screen md {
            font-size: 14px;
          }

          @screen lg {}

          &.txt1:before {
            display: block;
            position: absolute;
            content: '  ';
            background: #FFF url(/img/finder/kona_official_dealer_starts_3.png) no-repeat right top;
            width: 40px;
            height: 15px;
            left: -45px;
            top: 2px;

          }

          &.txt2:before {
            display: block;
            position: absolute;
            content: ' ';
            background: #FFF url(/img/finder/kona_official_dealer_starts_2.png) no-repeat right top;
            width: 40px;
            height: 15px;
            left: -45px;
            top: 2px;

          }

          &.txt3:before {
            display: block;
            position: absolute;
            content: ' ';
            background: #FFF url(/img/finder/kona_official_dealer_starts_1.png) no-repeat right top;
            width: 40px;
            height: 15px;
            left: -45px;
            top: 2px;

          }
        }
      }
    }

    // kona-dealers
  }
}