.contact,
.inquiry {
  .contact-title {
    span.pre {
      display: block;
      font-size: 0.3em;
      position: absolute;
      left: 5px;
      top: -25px;
    }
  }
  .v3dp__clearable {
    position: absolute !important;
    left: auto;
    right: 10px !important;
    top: 0 !important;
  }

  .resize {
    textarea {
      resize: vertical;
    }
  }
}
