.VueHoverfade-enter-active,
.VueHoverfade-leave-active {
  transition: opacity .5s
}

.VueHoverfade-enter,
.VueHoverfade-leave-to {
  opacity: 0
}

.vh--outer[v-cloak] {
  display: none
}

.vh--flex {
  display: flex
}

.vh--jc {
  justify-content: center
}

.vh--ai {
  align-items: center
}

.vh--rel {
  position: relative
}

.vh--abs {
  position: absolute
}

.vh--outer {
  display: inline-block;
  line-height: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff
}

.vh--holder {
  overflow: hidden;
  touch-action: manipulation;
  cursor: zoom-in;
  align-items: flex-start
}

.vh--image {
  top: 0;
  left: 0;
  pointer-events: none
}

.vh--message {
  background-color: #000000a6;
  padding: 8px 15px;
  border-radius: 50px;
  text-align: center;
  line-height: initial
}

.vh--message-top {
  top: 20px
}

.vh--message-bottom {
  bottom: 20px
}

.vh--icon {
  transform: rotate(-45deg);
  display: block;
  font-size: 20px;
  margin-right: 5px;
  line-height: 20px
}

.vh--close {
  line-height: 0;
  background-color: #000000a6;
  border-radius: 50px;
  font-size: 23px;
  cursor: pointer;
  height: 28px;
  width: 28px
}

.vh--top-left {
  top: 5px;
  left: 5px
}

.vh--top-right {
  top: 5px;
  right: 5px
}

.vh--top-center {
  top: 5px;
  left: 50%;
  transform: translate(-50%)
}

.vh--bottom-left {
  bottom: 5px;
  left: 5px
}

.vh--bottom-right {
  bottom: 5px;
  right: 5px
}

.vh--bottom-center {
  bottom: 5px;
  left: 50%;
  transform: translate(-50%)
}

.vh--loading-o {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a6;
  pointer-events: none
}

.vh--loading {
  top: 50%;
  left: 50%;
  font-size: 60px;
  line-height: 60px;
  animation: vuehoverzoomspin 1s linear infinite;
  width: 36px;
  height: 70px
}

.vh--none {
  opacity: 0
}

.vh--no-click img {
  pointer-events: none
}

@keyframes vuehoverzoomspin {
  0% {
    transform: rotate(0)
  }

  to {
    transform: rotate(360deg)
  }
}