#brandDetail_v2 {
  .brand-carousel {
      @apply relative bg-white text-center border-b-2 border-[#eee] py-[50px] sm:py-[50px] md:py-[50px] lg:py-[80px];
      .slick-track { 
        min-width: 10000px !important;
      }

      .item{
        @apply relative block px-10 lg:flex;

        .img{
          @apply w-full;
        }

        .info{
          @apply flex items-center flex-wrap text-left w-full;
          .title1{
            @apply text-[24px] xs:text-[26px] sm:text-[28px] md:text-[30px] leading-[0.85] font-bold mb-3 sm:mb-4 md:mb-4;
          }
          h3{
            @apply w-full;
            span{
              @apply block;
            }
          }
          p {
            @apply w-full;
          }
        }
      }

    }
}