.news-detail {
  
  .main-contents{
    @apply relative;
    .date{
      @apply font-rift font-semibold text-[14px] sm:text-[15px] pb-[3px] xs:pb-[4px] sm:pb-[5px];
    }
    .title{
      @apply text-[17px] xs:text-[18px] sm:text-[19px] lg:text-[20px] font-bold mb-[1.5em] leading-[1.6];
    }
    h1, h2, h3, h4, h5, h6, p, img{
      @apply mb-[20px];
      &:last-child{
        @apply mb-0;
      }
    }
    p{
      @apply leading-[2.2];
    }
    table{
      img{
        @apply mb-0;
      }
    }
    iframe{
      @apply w-full;
    }
    hr{
      @apply my-[20px]
    }
  }

  .news-detail-slide{
    .inner{
      @apply relative w-full h-full mx-[1px] md:mx-0;
    }
    .img{
      @apply relative md:h-full md:w-full;
      img{
        @apply mb-0 w-full;
      }
    }
    .txt{
      @apply relative z-10 pt-[20px] md:hidden md:absolute md:top-0 md:left-0 md:w-full md:h-full md:items-center md:text-white md:overflow-y-auto md:p-0 md:bg-[rgba(0,0,0,0.8)] text-left;
      @extend .custom-scrollbar;
      &:hover{
        @apply md:grid;
      }
      h2{
        @apply font-bold mb-[10px] md:mb-[20px];
      }
      p{
        @apply leading-[1.5] md:leading-[2];
      }
      & > div{
        @apply w-full md:p-[50px];
        p:last-child{
          @apply mb-0
        }
      }
    }
    .btn{
      @apply md:float-right md:w-[40px] md:h-[40px] md:mt-[-40px];
      img{
        @apply relative z-20 w-full hidden md:block #{!important};
        &:hover{
          &+.txt{
            @apply md:grid;
          }
        }
      }
    }
  }
}
